.dashboard-modal-box {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-modal-box .dashboard-modal-content {
  width: 25%;
  background-color: #fff;
  padding: 1rem 1.5rem;
  border-radius: 15px;
}

.dashboard-modal-box .dashboard-modal-content .dashboard-modal h3 {
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #245293;
}

.dashboard-modal-box .dashboard-modal-content .dashboard-modal .box-timesheet {
  border-radius: 12px;
  border: 1px solid #eff3f7;
  padding: 1rem;
  margin-bottom: 1rem;
}

.dashboard-modal-box
  .dashboard-modal-content
  .dashboard-modal
  .box-timesheet
  .overscroll-box-timesheet {
  height: 200px;
  overflow: scroll;
  overflow-x: hidden;
  margin-right: -17px;
  padding-right: 17px;
}

.spacing-top {
  margin-top: 2rem;
}

.dashboard-modal-box
  .dashboard-modal-content
  .dashboard-modal
  .box-timesheet
  h4 {
  color: #245293;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -1px;
}

.dashboard-modal-box
  .dashboard-modal-content
  .dashboard-modal
  .box-timesheet
  .timerange {
  margin-top: 1.2rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  color: #808080;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.5px;
}

.dashboard-modal-box
  .dashboard-modal-content
  .dashboard-modal
  .box-timesheet
  .timerange
  .each-timerange {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.dashboard-modal-box
  .dashboard-modal-content
  .dashboard-modal
  .box-timesheet
  .timerange
  .middle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-modal-box
  .dashboard-modal-content
  .dashboard-modal
  .box-timesheet
  .box-timesheet-input-cover {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  width: 100%;
  border-bottom: 1px solid #eff3f7;
  padding-bottom: 1rem;
  margin-bottom: 0.5rem;
}

.dashboard-modal-box
  .dashboard-modal-content
  .dashboard-modal
  .box-timesheet
  .box-timesheet-input-cover
  .hours-bg {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 10%;
}

.dashboard-modal-box
  .dashboard-modal-content
  .dashboard-modal
  .box-timesheet
  .box-timesheet-input-cover
  .hours-bg
  label {
  color: #021424;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: -0.5px;
}

.dashboard-modal-box
  .dashboard-modal-content
  .dashboard-modal
  .box-timesheet
  .box-timesheet-input-cover
  .to {
  color: #808080;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: -0.5px;
  margin-top: 2rem;
}

.dashboard-modal-box
  .dashboard-modal-content
  .dashboard-modal
  .box-timesheet
  button {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border-radius: 100px;
  border: 1.5px solid #245293;
  background: #fff;
  padding: 14px 24px;
  color: #245293;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
}

.dashboard-modal-box .dashboard-modal .dashboard-modal-btn {
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-modal-box .dashboard-modal .dashboard-modal-btn button {
  padding: 0.35rem 0;
  width: 175px;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 100px;
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  cursor: pointer;
}

.dashboard-modal-box .dashboard-modal .dashboard-modal-btn .btn-cancel {
  background: #db3e4d;
}

.dashboard-modal-box .dashboard-modal .dashboard-modal-btn .btn-submit {
  background: #3a649e;
}

/* review modal */
.dashboard-modal-box .dashboard-modal {
  /* border: 1px solid red; */
}

.dashboard-modal-box .dashboard-modal hr {
  border-top: 1px solid #a9dbee;
  margin: 1rem 0 0 0.4rem;
}

.dashboard-modal-box .dashboard-modal .review-logo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dashboard-modal-box .dashboard-modal .review-logo .review-logo-img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid grey;
  padding: 5px;
  margin: 0.8rem 0;
}

.dashboard-modal-box .dashboard-modal .review-logo .review-logo-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dashboard-modal-box .dashboard-modal .review-logo h4 {
  font-size: 15px;
  color: #245293;
}

.dashboard-modal-box .dashboard-modal .review-logo .title-bg .rate {
  margin-top: 3px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.dashboard-modal-box .dashboard-modal .review-logo .title-bg .rate span {
  color: #aabcd6;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
}

.dashboard-modal-box .dashboard-modal .experience-review-box {
  margin-top: 20px;
  width: 100%;
}

.dashboard-modal-box .dashboard-modal .experience-review-box p {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.dashboard-modal-box .dashboard-modal .experience-review-box textarea {
  margin: 0.3rem 0 1rem;
  width: 100%;
  height: 96px;
  padding: 1rem;
  border-radius: 100px;
  border: 1px solid #efefef;
  background: #fff;
}

/* review modal */

@media only screen and (max-width: 1300px) {
  .dashboard-modal-box .dashboard-modal-content {
    width: 60%;
  }
}

@media only screen and (max-width: 900px) {
  .dashboard-modal-box .dashboard-modal-content {
    width: 70%;
  }

  .dashboard-modal-box
    .dashboard-modal-content
    .dashboard-modal
    .box-timesheet
    .box-timesheet-input-cover {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
  }

  .dashboard-modal-box
    .dashboard-modal-content
    .dashboard-modal
    .box-timesheet
    .box-timesheet-input-cover
    .to {
    margin-top: 0;
    align-items: center;
    align-self: center;
  }

  .dashboard-modal-box
  .dashboard-modal-content
  .dashboard-modal
  .box-timesheet
  .box-timesheet-input-cover
  .hours-bg {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 30%;
}
}

@media only screen and (max-width: 800px) {
  .dashboard-modal-box .dashboard-modal-content {
    width: 85%;
  }
  .dashboard-modal-box .dashboard-modal .experience-review-box {
    padding: 0.5rem 0;
  }

  .dashboard-modal-box .dashboard-modal .experience-review-box textarea {
    height: 150px;
    border-radius: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .dashboard-modal-box
    .dashboard-modal-content
    .dashboard-modal
    .box-timesheet {
    border-radius: 8px;
    border: 1px solid #eff3f7;
    padding: 12px;
    margin-bottom: 2rem;
  }

  .dashboard-modal-box
    .dashboard-modal-content
    .dashboard-modal
    .box-timesheet
    .timerange {
    margin-top: 1.2rem;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
    color: #808080;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.5px;
  }

  .dashboard-modal-box
    .dashboard-modal-content
    .dashboard-modal
    .box-timesheet
    .timerange
    .each-timerange {
    display: flex;
    align-items: center;
    gap: 0.2rem;
  }

  .dashboard-modal-box
    .dashboard-modal-content
    .dashboard-modal
    .box-timesheet
    .timerange
    .middle {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  .dashboard-modal-box .dashboard-modal-content {
    width: 95%;
    padding: 1rem 1.1rem;
  }

  .dashboard-modal-box .dashboard-modal .dashboard-modal-btn button {
    width: 150px;
  }
}

.modal-test-bg {
  padding: 1.5rem 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 1rem;
}

.modal-test-bg .upload-img-bg {
  display: flex;
  align-items: center;
  gap: 10px;
}

.modal-test-bg .upload-img-bg input {
  visibility: hidden;
}

.modal-test-bg .upload-img-bg img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid gray;
}

.modal-test-bg .test-form {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  gap: 1rem;
}

.settings-container .settings-inner {
  padding: 1.5rem 0.9rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 2rem;
}

.settings-container .settings-inner .settings-part h3 {
  font-size: 22px;
  color: rgba(36, 82, 147, 1);
}

.settings-container .settings-inner .settings-part .settings-board {
  background-color: #fff;
  border-radius: 10px;
  padding: 1.5rem;
  margin-top: 1rem;
}

.content .data-content {
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  width: 100%;
  gap: 1rem;
}

.content .check-mobile {
  display: none;
}

.content .data-content .dot-bg {
  width: 10%;
  color: #00054F;
  text-align: center;
}

.content .dot-bg-mobile {
  display: none;
}

.content .data-content .part-tr {
  width: 22.5%;
  text-align: center;
}

.content .data-content .part-tr h5 {
  display: none;
}

.content .data-content .part-tr h6 {
  color: #1c2533;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.content .data-content .status {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  border-radius: 24px;
  padding: 5px 9px;
  width: fit-content;
}

.content .data-content .pend {
  color: #f5a656;
  background: rgba(245, 166, 86, 0.15);
}

.content .data-content .veri {
  color: green;
  background: rgba(19, 220, 124, 0.15);
}

.content .data-content .rej {
  color: red;
  background: rgb(226, 159, 159);
}

.content .data-content .blo {
  color: grey;
  background: rgb(219, 218, 218);
}

@media only screen and (max-width: 850px) {
  .content {
    display: flex;
    gap: 0.5rem;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0.3rem 0 1rem;
  }
  .content .check-mobile {
    display: block;
    margin-top: 4px;
  }

  .content .data-content .check {
    display: none;
  }

  .content .data-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
    width: 100%;
    gap: 0.5rem;
  }

  .content .data-content .part-tr h5 {
    width: 40%;
    text-align: left;
  }

  .content .data-content .part-tr h6 {
    width: 60%;
    text-align: left;
  }

  .content .data-content .part-tr .status-bg {
    width: 60%;
    display: flex;
    align-items: flex-start;
  }

  .content .data-content .status {
    width: fit-content;
  }

  .content .data-content .part-tr {
    width: auto;
    display: flex;
    align-items: center;
    gap: 3rem;
    width: 100%;
  }

  .content .data-content .dot-bg {
    display: none;
  }

  .content .data-content .part-tr h5 {
    display: block;
    color: #1c2533;
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
  }

  .content .dot-bg-mobile {
    display: block;
    color: #00054F;
  }
}

@media only screen and (max-width: 850px) {
  .content .data-content .part-tr {
    gap: 2rem;
  }
}

.location-settings {
  display: flex;
  flex-direction: column;
  gap: 10;
}

.location-settings .loc-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.location-settings .loc-top .loc-input-bg {
  display: flex;
  align-items: flex-end;
  gap: 20px;
  width: 45%;
}

.location-settings .loc-top .loc-input {
  flex: 1;
}

.loc-content {
  margin-top: 2rem;
}

.loc-content h5 {
  font-size: 18px;
  font-weight: 500;
}

.loc-content .loc-states {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 1rem;
}

.loc-content .loc-states .loc-empty {
  font-size: 17px;
  font-weight: 500;
  width: 100%;
  text-align: center;
  margin: 1rem 0;
}

.loc-content .loc-states .loc-states-each {
  border-radius: 2px;
  border: 1px solid rgb(160, 160, 160);
  background-color: #fffdfd;
  color: #606060;
  font-size: 16px;
  font-weight: 500;
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
}

.loc-content .loc-states .loc-states-each .main {
  padding: 5px 7px;
}

.loc-content .loc-states .loc-states-each span {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  text-transform: lowercase;
  padding: 5px 5px;
  background-color: rgb(230, 229, 229);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
}

.loc-content .loc-states .loc-states-each span:hover {
  background-color: rgb(136, 133, 133);
}

.modal-division-bg {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgb(240, 240, 240);
}

.modal-division-bg .modal-division-bg-header {
  background-color: #245293;
  padding: 15px 0;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.modal-division-bg .modal-division-bg-body {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 2rem;
  gap: 1rem;
}

.modal-division-bg .modal-division-bg-body .auto-complete-con {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  width: 50%;
}

.modal-division-bg .modal-division-bg-body .auto-complete-con .division-label {
  font-size: 20px;
  font-weight: 600;
}

.modal-division-bg .modal-division-bg-footer {
  padding: 0px 10px 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
}

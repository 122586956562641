.singleApplicantPageContainer {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 2rem;
}

.singleApplicantPageContainer .single-applicant-page-content {
  padding: 2rem;
  border-radius: 24px;
  background: #fff;
  width: 100%;
}

.singleApplicantPageContainer .single-applicant-page-content .page-content-top {
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 1rem 0;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-top
  .top-image {
  border-radius: 50px;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-top
  .top-image
  img {
  border-radius: 50px;
  height: 100px;
  width: 100px;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-top
  .top-name {
  display: flex;
  gap: 7px;
  align-items: center;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-top
  .top-name
  .status.verified {
  color: #245293;
  background: #eef7ff;
  padding: 2px 6px;
  border-radius: 9px;
  font-size: 15px;
  font-weight: 600;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-top
  .top-name
  .status.pending {
  background: #fef7e4;
  color: #f5a656;
  padding: 2px 6px;
  border-radius: 9px;
  font-size: 15px;
  font-weight: 600;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-top
  .top-name
  .status.rej {
  color: red;
  background: rgb(226, 159, 159);
  padding: 2px 6px;
  border-radius: 9px;
  font-size: 15px;
  font-weight: 600;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-top
  .top-name
  .status.blo {
  background: rgb(219, 218, 218);
  color: grey;
  padding: 2px 6px;
  border-radius: 9px;
  font-size: 15px;
  font-weight: 600;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-top
  .top-email {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-top
  .top-email
  .dot {
  color: white;
  font-size: 16px;
  font-weight: 600;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-top
  .top-email
  p {
  color: #808080;
  font-size: 14px;
  font-weight: 400;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-top
  .top-buttons {
  display: flex;
  gap: 17px;
  align-items: center;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-top
  .top-buttons
  .disable-btn {
  background-color: rgb(187, 184, 184);
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-top
  .top-buttons
  .btn {
  padding: 14px 32px;
  border: none;
  outline: none;
  font-size: 16px;
  border-radius: 100px;
  opacity: 0.6;
  width: 120px;
  cursor: pointer;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-top
  .top-buttons
  .btn.block {
  color: #db3e4d;
  background: #f9e3e0;
  font-weight: 700;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-top
  .top-buttons
  .btn.reject {
  background: #e5f3ff;
  color: #245293;
  font-weight: 700;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-top
  .top-buttons
  .btn.approve {
  background: #245293;
  color: #fff;
  font-weight: 700;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-bottom
  .bottom-head {
  border-bottom: 1px solid #dddddd;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-bottom
  .bottom-head
  p {
  color: #808080;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.28px;
  padding: 0 15px 10px;
  width: fit-content;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-bottom
  .bottom-head
  .active-tab {
  color: #245293;
  border-bottom: 2px solid #245293;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-bottom
  .content {
  display: flex;
  gap: 1.5rem;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-bottom
  .content
  .box {
  border: 1px solid #dddddd;
  border-radius: 0.5rem;
  min-height: 150px;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-bottom
  .content
  .box
  .upper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dddddd;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-bottom
  .content
  .box
  .middle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-bottom
  .content
  .box
  .upper-inner {
  border-right: 1px solid #dddddd;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-bottom
  .content
  .box
  .space {
  padding: 1rem;
  width: 33.3%;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-bottom
  .content
  .box
  .space-2 {
  padding: 1rem;
  width: 50%;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-bottom
  .content
  .box
  .space-2
  .skills-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.4rem;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-bottom
  .content
  .box
  p {
  color: rgba(128, 128, 128, 1);
  font-size: 15px;
  font-weight: 400;
  line-height: 18.5px;
  margin-top: 0.2rem;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-bottom
  .content
  .box
  h6 {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  color: rgba(0, 0, 0, 1);
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-bottom
  .content
  .box
  .inner-box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1.5rem;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-bottom
  .content
  .box
  .inner-box
  .link-inner {
  display: flex;
  align-items: flex-end;
  gap: 0.5rem;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-bottom
  .content
  .box
  .inner-box
  .link-inner
  p {
  font-size: 16px;
  line-height: 19.36px;
  font-weight: 400;
  text-decoration: underline;
  color: rgba(36, 82, 147, 1);
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-bottom
  .content
  .wider {
  width: 70%;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-bottom
  .content
  .smaller {
  width: 30%;
  padding: 1rem;
}

@media only screen and (max-width: 1000px) {
  .singleApplicantPageContainer {
    min-height: 100vh;
    margin: 1rem 1rem 1rem;
  }
}

@media only screen and (max-width: 850px) {
  .singleApplicantPageContainer {
    min-height: 100vh;
    margin: 5rem 1rem 1rem;
  }

  .singleApplicantPageContainer
    .single-applicant-page-content
    .page-content-top
    .top-buttons {
    display: flex;
    gap: 17px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 500px) {
  .singleApplicantPageContainer .single-applicant-page-content {
    padding: 2rem 1rem;
    border-radius: 18px;
  }

  .singleApplicantPageContainer
    .page-content-bottom
    .bottom-boxes
    .box-content {
    width: 420px;
    border-radius: 10px;
    padding: 30px 15px;
  }
}

.test-container {
  padding: 1.5rem 0.9rem;
  width: 100%;
}

.test-container h3 {
  font-size: 22px;
  color: rgba(36, 82, 147, 1);
}

.test-container .card-test {
  background-color: #fff;
  padding: 1rem;
}